import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../store'
import { useHistory } from 'react-router-dom'
import droneIcon from '../../assets/drone.svg'
import closeIcon from '../../assets/close.svg'
import { REGISTRATION_PATH } from '../../views/registration/View'

const Container = styled.div`
	background: #f6ae1e;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #2f313e;
	font-size: 18px;
	height: 70px;

	.bold {
		text-shadow: 1px 0 #888888;
		letter-spacing: 1px;
		font-weight: bolder;
		margin-right: 24px;
	}

	.highlight {
		color: #0d69d4;
		cursor: pointer;
		text-decoration: underline;
	}

	.close {
		position: absolute;
		right: 30px;
		cursor: pointer;
	}
`

export default function Banner() {
	const history = useHistory()
	const { t } = useTranslation()
	const { bannerClosed, closeBanner } = useStore()

	return (
		<Container
			style={{
				display: bannerClosed ? 'none' : 'flex',
			}}
		>
			<img src={droneIcon} alt="drone" style={{ marginRight: '24px' }} />
			<p
				style={{ fontWeight: 'bold', cursor: 'pointer' }}
				onClick={() =>
					(window.location.href = 'https://airteam.ai/helden-des-fortschritts')
				}
			>
				<span>{t('header.banner.question_part1')}</span>
				<span style={{ color: '#02B6E8' }}>
					{t('header.banner.question_part2')}
				</span>
				<span>{t('header.banner.text')}</span>{' '}
			</p>
			<img src={droneIcon} alt="drone" style={{ marginLeft: '24px' }} />
			<img
				src={closeIcon}
				alt="close"
				className="close"
				onClick={closeBanner}
			/>
		</Container>
	)
}
